import styled from 'styled-components'
import { media } from '@thesisedu/web/dist'
import { transparentize } from 'polished'

export const MDPageContent = styled.div`
  max-width: 800px;
  p,
  li {
    line-height: 2 !important;
  }

  h2 {
    margin-top: ${(props) => props.theme['@size-xl']};
    margin-bottom: ${(props) => props.theme['@size-s']};
  }
  h3 {
    margin-top: ${(props) => props.theme['@size-l']};
    margin-bottom: ${(props) => props.theme['@size-s']};
  }
  ul {
    list-style: none none;
    padding-left: ${(props) => props.theme['@size-xs']};
    margin: ${(props) => props.theme['@size-s']} 0;
    li {
      margin: ${(props) => props.theme['@size-xs']} 0;
      padding-left: ${(props) => props.theme['@size-m']};
      position: relative;
      font-size: ${(props) => props.theme['@font-size-lg']};
      &:before {
        display: block;
        content: ' ';
        position: absolute;
        top: 0.65em;
        left: 0;
        width: 8px;
        height: 8px;
        background: ${(props) => props.theme['@gray-2']};
        border-radius: 50%;
      }
    }
  }

  .custom-block.image-left {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: ${(props) => props.theme['@size-m']} 0;
    & + .custom-block.image-left {
      padding-top: ${(props) => props.theme['@size-m']};
      border-top: solid 2px ${(props) => props.theme['@border-color-split']};
    }
    ${media.lg} {
      flex-wrap: nowrap;
    }
    > .custom-block-heading {
      display: block;
      min-width: 200px;
      max-width: 300px;
      margin: 0 auto;
      ${media.lg} {
        margin: 0;
      }
    }
    &.image-left-web > .custom-block-heading {
      min-width: 300px;
      max-width: 500px;
    }
    > .custom-block-body {
      margin: ${(props) => props.theme['@size-s']} 0 0 0;
      text-align: center;
      flex-grow: 1;
      ${media.lg} {
        margin: ${(props) => props.theme['@size-s']} 0 0 ${(props) => props.theme['@size-m']};
        text-align: left;
        > :first-child {
          margin-top: 0;
        }
      }
    }
  }
  .custom-block.block-info,
  .custom-block.block-warning {
    border-radius: ${(props) => props.theme['@border-radius-base']};
    padding: ${(props) => props.theme['@padding-sm']};
    margin-bottom: ${(props) => props.theme['@size-m']};
    ${media.lg} {
      padding: ${(props) => props.theme['@padding-lg']};
    }
    > .custom-block-heading {
      font-weight: bold;
      font-size: 115%;
    }
    > .custom-block-body *:last-child {
      margin-bottom: 0;
    }
  }
  .custom-block.block-info {
    background: ${(props) => transparentize(0.9, props.theme['@blue'])};
    > .custom-block-heading,
    a {
      color: ${(props) => props.theme['@blue']};
    }
  }
  .custom-block.block-warning {
    background: ${(props) => transparentize(0.9, props.theme['@orange'])};
    > .custom-block-heading,
    a {
      color: ${(props) => props.theme['@orange']};
    }
  }
`
