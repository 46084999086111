import * as React from 'react'
import { graphql } from 'gatsby'

import { Block } from '@thesisedu/web/dist'
import { PageTemplateQueryQuery } from '../graphqlTypes'
import { Container, LargeH1 } from '../components/styled'
import { renderAst } from '../components/rehype'
import { SEO } from '../components/SEO'
import { MDPageContent } from '../components/MDPageContent'

export interface PageTemplateProps {
  data: PageTemplateQueryQuery
}
const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => (
  <Container>
    <SEO title={data.markdownRemark.frontmatter.title} article />
    <Block marginTop={'@size-section-md'} marginBottom={'@size-xxl'}>
      <LargeH1 isBlock={false}>{data.markdownRemark.frontmatter.title}</LargeH1>
    </Block>
    <MDPageContent>{renderAst(data.markdownRemark.htmlAst)}</MDPageContent>
  </Container>
)

export default PageTemplate

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      excerptAst
      frontmatter {
        title
      }
    }
  }
`
